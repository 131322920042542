<template>
  <b-card class="dashed-action-card text-center">
    <b-button @click="addNewClient" variant="flat-primary" class="add-button">
      <div>
        <feather-icon
          class="mb-1"
          icon="PlusCircleIcon"
          size="32"
          color="#ffffff"
        ></feather-icon>
      </div>
      Add New
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BButton } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BButton,
  },
  methods: {
    addNewClient() {
      this.$emit("add-new", true);
    },
  },
};
</script>

<style>
</style>