<template>
  <div>
    <b-modal no-close-on-backdrop v-model="editClient" id="modal-center" hide-footer hide-header centered>
      <b-card-text>
        <div class="header text-right">
          <b-button @click="closeClientPopUp" variant="flat-primary"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div>
        <div class="pl-2 pr-2 pb-2 pt-0 edit-form">
          <h3 class="app-title lg">
            {{ action === "add-new" ? "Add New Client" : "Edit Client" }}
          </h3>
          <div class="form-fields mt-3">
            <b-form-group>
              <label for="client-name">Client Name <span class="validation">*</span></label>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" class="cursor-pointer" />
                </b-input-group-prepend>
                <b-form-input v-model="client.name" id="client-name" placeholder="Enter client name" />
              </b-input-group>
            </b-form-group>
            <b-form-group>
              <label for="client-name">Sector <span class="validation">*</span></label>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="PieChartIcon" class="cursor-pointer" />
                </b-input-group-prepend>
                <b-form-select placeholder="Select client sector" v-model="client.sector" :options="clientSectors" />
              </b-input-group>
            </b-form-group>
            <b-col cols="12" class="mt-1"><label>Type of Business <span class="validation">*</span></label>
              <br />
              <b-form-group>
                <v-select label="name" placeholder="Select Business(s) type" v-model="client.selectedBusinessType"
                  multiple :options="businessType" 
                  :deselectFromDropdown="true"/>
              </b-form-group>
            </b-col>
            <b-button @click="addClient" :disabled="isBusy || isBtnDisabledForAddClient" class="py-1 mt-2" block
              variant="primary">
              <b-spinner v-if="isBusy" small class="mr-1" />
              {{
                action === "add-new" ? "Create Client" : "Make Changes"
              }}</b-button>
          </div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import {
  BModal,
  BCardText,
  BButton,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BFormSelect,
  BSpinner,
  BCol
} from "bootstrap-vue";
export default {
  props: {
    editClient: {
      type: Boolean,
      default: false,
    },
    method: { type: Function },
    client: {
      type: Object,
      default: () => {
        return {
          clientID: "",
          name: "",
          sector: null,
          selectedBusinessType: []
          // hourly_budget: "",
        };
      },
    },
    action: {
      type: String,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      name: "",
      // hourly_budget: "",
      sector: null,
      ClientId: "",
      clientSectors: [
        { value: null, text: "Select Sector", disabled: true },
        "E-Commerce",
        "Healthcare",
        "Education",
        "Real Estate",
        "Retail",
        "App",
        "Custom",
      ],
      selectedBusinessType: [],
      businessType: [
        "Ecommerce",
        "Leadgen",
        "Branding",
        "Apps"
      ],
    };
  },
  components: {
    BModal,
    BCardText,
    BButton,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BFormSelect,
    BSpinner,
    vSelect,
    BCol
  },
  computed: {
    isBtnDisabledForAddClient() {
      return (
        this.selectedBusinessTypeLowercase.length === 0 ||
        this.client.name === "" ||
        this.client.sector === null
      );
    },
    selectedBusinessTypeLowercase() {
      return this.client.selectedBusinessType.map(type => type.toLowerCase());
    }
  },
  methods: {
    addClient(bvModalEvt) {
      bvModalEvt.preventDefault();
      const name = this.client.name;
      const sector = this.client.sector;
      const businessType = this.selectedBusinessTypeLowercase;
      // const hourly_budget = this.client.hourly_budget;
      const clientID = this.client.clientID;
      let url = `${process.env.VUE_APP_SERVER_URL}/api/client/add`;
      let toastTitle = `Created`;
      let toastMessage = `Client created successfully!`;

      if (clientID) {
        url = `${process.env.VUE_APP_SERVER_URL}/api/client/update`;
        toastTitle = `Updated`;
        toastMessage = `Client updated successfully!`;
      }
      axios
        .post(
          url,
          {
            sector,
            // hourly_budget,
            name,
            clientID,
            businessType
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.showToast(toastTitle, toastMessage, "success");
          this.method();
          this.processData();
          this.selectedBusinessType = [];
          this.selectedSector = [];
          this.name = "";
          // this.hourlyBudget = "";
          this.editClient = true;
          this.$emit("closeClientPopUp", true);
        })
        .catch((error) => {
          console.log(error);
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },

    closeClientPopUp() {
      this.$emit("closeClientPopUp", true);
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    processData() {
      if (this.action === "edit-client") {
        // this.$emit("edit-client", this.client);
      } else if (this.action === "add-new") {
        this.$emit("add-new", this.client);
      }
    },
  },
};
</script>

<style>
.validation {
  color: red;
  margin-left: 2px;
  border-radius: 5px;
}
</style>
