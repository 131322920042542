<template>
  <div>
    <b-card class="custom-card">
     <div style="display: flex; justify-content:space-between">
      <div>
         <b-card-text style="text-transform: capitalize; color:#021526;font-weight:500 "><span>{{client.businessType[0]}}</span></b-card-text>
      </div>
      <div class="custom-card-header d-flex">
        <b-button :disabled="client.role != 'ADMIN'" @click="accessControl" variant="flat-success" class="btn-icon rounded-circle custom-icon">
          <feather-icon size="18" icon="UserPlusIcon"></feather-icon>
        </b-button>
        <!-- Only admin have permission to access this dropdown menu. -->
        <b-nav-item-dropdown :disabled="client.role != 'ADMIN'" right toggle-class="d-flex align-items-center">
          <template #button-content>
            <feather-icon size="18" class="more-icon" icon="MoreVerticalIcon"></feather-icon>
          </template>

          <b-dropdown-item @click="editClient" link-class="d-flex align-items-center">
            <feather-icon size="16" icon="Edit3Icon" class="mr-50" />
            <span>Edit Name</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="client.integrations.googleAds && client.googleAdsCustomerID || client.integrations.amazon && client.amazon.profileIDs[0] || client.integrations.facebook && client.facebook.adAccountIDs[0]"
            @click="sendSchedule(client._id)" link-class="d-flex align-items-center">
            <feather-icon size="16" icon="SendIcon" class="mr-50" />
            <span>Schedule Trigger</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteClient" link-class="d-flex align-items-center">
            <feather-icon size="16" icon="Trash2Icon" class="mr-50 text-danger" />
            <span class="text-danger">Delete Client</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </div>
     </div>
      <b-card-text>
        <div class="mt-1 text-center" v-if="client.integrations.googleAds && client.googleAdsCustomerID || client.integrations.amazon && client.amazon.profileIDs[0] || client.integrations.facebook && client.facebook.adAccountIDs[0]">
          <b-avatar  :to="`/channels-dashboard/${client.slug}`" size="64" :text="avatarText(client.name)" variant="light-primary" />
          <b-link :to="`/channels-dashboard/${client.slug}`">
            <h3 class="mt-1">{{ client.name }}</h3>
          </b-link>
        </div>
        <div class="mt-1 text-center" v-else>
          <b-avatar :to="`/client/${client.slug}`" size="64" :text="avatarText(client.name)" variant="light-primary" />
          <b-link :to="`/client/${client.slug}`">
            <h3 class="mt-1">{{ client.name }}</h3>
          </b-link>
        </div>
      </b-card-text>

      <b-card-text class="connected-tools mt-2 mb-2">
        <div class="text-center" v-if="client.integrations.googleAds ||
          client.integrations.google ||
          client.integrations.facebook ||
          client.integrations.gmb ||
          client.integrations.amazon
          ">
          <p class="mb-0">Tools Connected</p>
        </div>
        <div class="tools mt-1">
          <b-col cols="10" class="mx-auto">
            <div v-if="!client.integrations.googleAds &&
              !client.integrations.google &&
              !client.integrations.facebook &&
              !client.integrations.gmb && !client.integrations.amazon 
              " class="text-center">
              <b-button size="sm" variant="primary" :to="`/client/${client.slug}`">Connect Tools <feather-icon
                  icon="ArrowRightIcon"></feather-icon></b-button>
            </div>
            <b-row v-else>
              <div style="display:flex;justify-content:center">
                <b-col  v-if="client.integrations.google && client.googleViewID" cols="3">
                  <b-link :to="`/client/${client.slug}/dashboard/google-analytics`">
                    <b-img class="w-100" src="@/assets/images/portrait/small/analytics-avatar.png"
                      alt="google-analytics"></b-img>
                  </b-link>
                </b-col>
                <b-col  v-if="client.integrations.googleAds && client.googleAdsCustomerID" cols="3">
                  <b-link :to="`/client/${client.slug}/dashboard/google-ads`">
                    <b-img class="w-100" src="@/assets/images/portrait/small/ads-avatar.png" alt="google-ads"></b-img>
                  </b-link>
                </b-col>
                <b-col  v-if="client.integrations.gmb && client.gmb.accountID.length" cols="3">
                  <b-link :to="`/gmb/${client.slug}`">
                    <b-img class="w-100" src="@/assets/images/logo/gmb.jpeg" alt="gmb"></b-img>
                  </b-link>
                </b-col>
                <b-col  v-if="client.integrations.facebook" cols="3">
                  <b-link :to="`/client/${client.slug}/dashboard/meta-ads`">
                    <b-img class="w-100" src="@/assets/images/logo/meta.png" alt="meta-ads"></b-img>
                  </b-link>
                </b-col>
                <b-col  v-if="client.integrations.amazon" cols="3">
                  <b-link :to="`/client/${client.slug}/dashboard/amazon-ads`">
                    <b-img class="w-100" src="@/assets/images/logo/amazon-logo.webp" alt="amazon-ads"></b-img>
                  </b-link>
                </b-col>
              </div>
            </b-row>
          </b-col>
        </div>
      </b-card-text>
    </b-card>
    <transition name="modal">
      <div class="modal-mask" v-if="openScheduleModal">
        <TriggerModalAll @closeScheduleClientPopUp="closeScheduleClientPopUp" :clientID="client._id"
          :scheduleModal="this.openScheduleModal" :isTriggerModal="isTriggerModal" />
      </div>
    </transition>
  </div>
</template>

<script>
import TriggerModalAll from "@/components/client/TriggerModalAll.vue"
import client from "@/router/client";
import {
  BCard,
  BCardText,
  BLink,
  BImg,
  BRow,
  BCol,
  BButton,
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
} from "bootstrap-vue";
export default {
  data() {
    return {
      openScheduleModal: false,
    }
  },
  props: {
    client: {
      type: Object,
      default: () => {
        return {
          openScheduleModal: false,
          name: "",
          slug: "",
          integrations: [],
          googleViewID: "",
          googleAdsCustomerID: "",
          sector: "",
          role: "",
          triggerData: [],
          showTriggerModal: false,
          isTriggerModal: false
        };
      },
    },
  },
  components: {
    BAvatar,
    BCard,
    BCardText,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BNavItemDropdown,
    BDropdownItem,
    TriggerModalAll
  },
  methods: {
    sendSchedule() {
      this.clientID = this.client._id;
      this.openScheduleModal = true;
      this.isTriggerModal = false
    },
    closeScheduleClientPopUp() {
      this.openScheduleModal = false;
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    editClient() {
      this.$emit("editClient", {
        clientID: this.client._id,
        name: this.client.name,
        sector: this.client.sector,
        selectedBusinessType: this.client.businessType
      });
    },
    deleteClient() {
      this.$emit("deleteClient", {
        name: this.client.name,
        clientID: this.client._id,
      });
    },
    accessControl() {
      this.$emit("accessControl", {
        name: this.client.name,
        clientID: this.client._id,
      });
    },
  },
};
</script>

<style>
.disable-scroll {
  overflow: hidden;
}
.animated-message-text-business {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 10px;
}

.animated-message-text-business span {
  position: absolute;
  width: 90%;
  font-size: 12px;
  color: transparent;
  background-image: linear-gradient(to right, #553c9a, #ee4b2b, #00c2cb, #ff7f50, #553c9a);
  -webkit-background-clip: text;
  background-clip: text;
  background-size: 200%;
  background-position: -200%;
  animation: animated-gradient 2s infinite alternate-reverse;
}

@keyframes animated-gradient {
  to {
    background-position: 200%;
  }
}
</style>