<template>
  <div>
    <b-modal
      no-close-on-backdrop
      v-model="show"
      id="modal-center"
      hide-footer
      hide-header
      centered
    >
      <b-card-text>
        <div class="header text-right">
          <b-button @click="closePopUp" variant="flat-primary"
            ><feather-icon icon="XIcon"></feather-icon
          ></b-button>
        </div>
        <div class="pl-2 pr-2 pb-3 pt-0 edit-form">
          <h3 class="app-title lg">Confirm Delete?</h3>

          <p class="mt-2">
            Are you sure want to delete "{{ deleteClientDetails.name }}"? You
            cannot undo this action.
          </p>

          <div class="d-flex justify-content-center mt-3">
            <b-button @click="confirmDelete" variant="danger" class="mr-1">
              <b-spinner v-if="isBusy" small class="mr-1" />
              <feather-icon v-else icon="Trash2Icon"></feather-icon>
              Delete</b-button
            >
            <b-button @click="closePopUp" variant="primary"
              ><feather-icon icon="XCircleIcon"></feather-icon> Cancel</b-button
            >
          </div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import { BModal, BCardText, BButton, BSpinner } from "bootstrap-vue";
export default {
  props: {
    isBusy: {
      type: Boolean,
      required: true,
      default: false,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    deleteClientDetails: {
      type: Object,
      required: true,
      default: () => {
        return {
          name: "",
          clientID: "",
        };
      },
    },
  },
  data() {
    return {};
  },
  components: {
    BModal,
    BCardText,
    BButton,
    BSpinner,
  },
  methods: {
    closePopUp() {
      this.$emit("closePopUp", true);
    },
    confirmDelete() {
      this.$emit("confirmDelete", {
        clientID: this.deleteClientDetails.clientID,
      });
    },
  },
};
</script>

<style>
</style>